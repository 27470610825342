import React from 'react';
// import Form from './Components/others';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navigation from './Components/landingpage';
import Home from './Components/homeForm'
import GeneralLiabilityForm from './Components/generalLiability'
import AutoInsurance from './Components/autoInsurance';
import Commercial from './Components/commercialAutoInsurance';
import Lifetest from './Components/Life'
import FloodInsuranceForm from './Components/FloodInsurance'
import ThankYou from './Components/thankYou'
import ContactCaseForm from './Components/advisorContactCase'
import ContactLeadForm from './Components/advisorContactLead'
import './App.css';
import Header from './Components/header'
import MainHeader from './Components/mainHeader';
import OthersQuote from './Components/others'
import { ErrorPage } from './Components/errorPage';

function App() {
    var header = false

    var QueryString = new URLSearchParams(window.location.search);
    var Ownerid = QueryString.get("agentId");
    var parameterid = QueryString.get("parameterId");
    var OwnerId = (Ownerid) ? Ownerid.toLowerCase() : Ownerid;
    var parameterId: any = (parameterid) ? parameterid.toLowerCase() : parameterid;

    const routesArray: string[] = ['othersinsurance', 'homeinsurance', 'autoinsurance', 'getintouch',
        'contactus', 'connectwithus', 'commercialautoinsurance', 'generalliabilityinsurance', 'floodinsurance', 'thankyou', 'lifeinsurance']

    // if(window.location.href.toLowerCase().endsWith('othersinsurance')||
    // window.location.href.toLowerCase().endsWith('othersinsurance#')||
    // window.location.href.toLowerCase().endsWith('othersinsurance?')||
    // window.location.href.toLowerCase().endsWith(`othersinsurance?agentid=${OwnerId}`)||
    // window.location.href.toLowerCase().endsWith(`othersinsurance/?agentid=${OwnerId}`)||
    // window.location.href.toLowerCase().endsWith(`othersinsurance?parameterid=${parameterId}`)||
    // window.location.href.toLowerCase().endsWith(`othersinsurance/?parameterid=${parameterId}`)||
    // window.location.href.toLowerCase().endsWith('othersinsurance/')||
    // window.location.pathname === "/" ||
    // window.location.href.toLowerCase().endsWith('homeinsurance')||
    // window.location.href.toLowerCase().endsWith('homeinsurance#')||
    // window.location.href.toLowerCase().endsWith('homeinsurance?')||
    // window.location.href.toLowerCase().endsWith(`homeinsurance?agentid=${OwnerId}`)||
    // window.location.href.toLowerCase().endsWith(`homeinsurance/?agentid=${OwnerId}`)||
    // window.location.href.toLowerCase().endsWith(`homeinsurance?parameterid=${parameterId}`)||
    // window.location.href.toLowerCase().endsWith(`homeinsurance/?parameterid=${parameterId}`)||
    // window.location.href.toLowerCase().endsWith('homeinsurance/')||
    // window.location.href.toLowerCase().endsWith('autoinsurance')||
    // window.location.href.toLowerCase().endsWith('autoinsurance#')||
    // window.location.href.toLowerCase().endsWith('autoinsurance?')||
    // window.location.href.toLowerCase().endsWith(`autoinsurance?agentid=${OwnerId}`)||
    // window.location.href.toLowerCase().endsWith(`autoinsurance/?agentid=${OwnerId}`)||
    // window.location.href.toLowerCase().endsWith(`autoinsurance?parameterid=${parameterId}`)||
    // window.location.href.toLowerCase().endsWith(`autoinsurance/?parameterid=${parameterId}`)||
    // window.location.href.toLowerCase().endsWith('autoinsurance/')||
    // window.location.href.toLowerCase().endsWith('getintouch')||
    // window.location.href.toLowerCase().endsWith('getintouch#')||
    // window.location.href.toLowerCase().endsWith('getintouch?')||
    // window.location.href.toLowerCase().endsWith('getintouch/')||
    // window.location.href.toLowerCase().endsWith('contactus')||
    // window.location.href.toLowerCase().endsWith('contactus#')||
    // window.location.href.toLowerCase().endsWith('contactus?')||
    // window.location.href.toLowerCase().endsWith('contactus/')||
    // window.location.href.toLowerCase().endsWith('connectwithus')||
    // window.location.href.toLowerCase().endsWith('connectwithus#')||
    // window.location.href.toLowerCase().endsWith('connectwithus?')||
    // window.location.href.toLowerCase().endsWith('connectwithus/')||
    // window.location.href.toLowerCase().endsWith(`contactus?agentid=${OwnerId}`)||
    // window.location.href.toLowerCase().endsWith(`contactus/?agentid=${OwnerId}`)||
    // window.location.href.toLowerCase().endsWith(`contactus?parameterid=${parameterId}`)||
    // window.location.href.toLowerCase().endsWith(`contactus/?parameterid=${parameterId}`)||
    // window.location.href.toLowerCase().endsWith('commercialautoinsurance')||
    // window.location.href.toLowerCase().endsWith('commercialautoinsurance#')||
    // window.location.href.toLowerCase().endsWith('commercialautoinsurance?')||
    // window.location.href.toLowerCase().endsWith(`commercialautoinsurance?agentid=${OwnerId}`)||  
    // window.location.href.toLowerCase().endsWith(`commercialautoinsurance/?agentid=${OwnerId}`)||
    // window.location.href.toLowerCase().endsWith(`commercialautoinsurance?parameterid=${parameterId}`)||
    // window.location.href.toLowerCase().endsWith(`commercialautoinsurance/?parameterid=${parameterId}`)|| 
    // window.location.href.toLowerCase().endsWith('commercialautoinsurance/')||
    // window.location.href.toLowerCase().endsWith('generalliabilityinsurance')||
    // window.location.href.toLowerCase().endsWith('generalliabilityinsurance#')||
    // window.location.href.toLowerCase().endsWith('generalliabilityinsurance?')||
    // window.location.href.toLowerCase().endsWith(`generalliabilityinsurance?agentid=${OwnerId}`)||
    // window.location.href.toLowerCase().endsWith(`generalliabilityinsurance/?agentid=${OwnerId}`)||
    // window.location.href.toLowerCase().endsWith(`generalliabilityinsurance?parameterid=${parameterId}`)||
    // window.location.href.toLowerCase().endsWith(`generalliabilityinsurance/?parameterid=${parameterId}`)|| 
    // window.location.href.toLowerCase().endsWith('generalliabilityinsurance/')||
    // window.location.href.toLowerCase().endsWith('floodinsurance')||
    // window.location.href.toLowerCase().endsWith('floodinsurance#') ||
    // window.location.href.toLowerCase().endsWith('floodinsurance?') ||
    // window.location.href.toLowerCase().endsWith(`floodinsurance?agentid=${OwnerId}`) ||
    // window.location.href.toLowerCase().endsWith(`floodinsurance/?agentid=${OwnerId}`) ||
    // window.location.href.toLowerCase().endsWith(`floodinsurance?parameterid=${parameterId}`)||
    // window.location.href.toLowerCase().endsWith(`floodinsurance/?parameterid=${parameterId}`)|| 
    // window.location.href.toLowerCase().endsWith('floodinsurance/') ||
    // window.location.href.toLowerCase().endsWith('lifeinsurance')||
    // window.location.href.toLowerCase().endsWith('lifeinsurance#')||
    // window.location.href.toLowerCase().endsWith('lifeinsurance?')||
    // window.location.href.toLowerCase().endsWith(`lifeinsurance?agentid=${OwnerId}`)||
    // window.location.href.toLowerCase().endsWith(`lifeinsurance/?agentid=${OwnerId}`)||
    // window.location.href.toLowerCase().endsWith(`lifeinsurance?parameterid=${parameterId}`)||
    // window.location.href.toLowerCase().endsWith(`lifeinsurance/?parameterid=${parameterId}`)|| 
    // window.location.href.toLowerCase().endsWith('lifeinsurance/')||
    // window.location.href.toLowerCase().endsWith('thankyou')||
    // window.location.href.toLowerCase().endsWith('thankyou#')||
    // window.location.href.toLowerCase().endsWith('thankyou?')||
    // window.location.href.toLowerCase().endsWith('thankyou/'))
    // {
    //     header = true
    // }

    if (routesArray.some((route: string) => window.location.href.toLowerCase().split('/')[3].split('#')[0].split('?')[0] === route) || window.location.pathname === "/") header = true

    return (


        <BrowserRouter>

            {header ? <MainHeader /> : <ErrorPage />}
            <Routes>
                <Route path='/' element={<Navigation />}></Route>
                <Route path='/othersInsurance' element={header == true ? <OthersQuote /> : null}></Route>
                <Route path='/homeInsurance' element={header == true ? <Home /> : null}></Route>
                <Route path='/autoInsurance' element={header == true ? <AutoInsurance /> : null}></Route>
                <Route path='/getInTouch' element={<ContactCaseForm />}></Route>
                <Route path='/contactUs' element={<ContactLeadForm />}></Route>
                <Route path='/commercialAutoInsurance' element={header == true ? <Commercial /> : null}></Route>
                <Route path='/generalLiabilityInsurance' element={header == true ? <GeneralLiabilityForm /> : null}></Route>
                <Route path='/floodInsurance' element={header == true ? <FloodInsuranceForm /> : null}></Route>
                <Route path='/connectWithUs' element={header == true ? <ContactLeadForm name={'connectWithUs'} /> : null}></Route>
                <Route path='/lifeInsurance' element={header == true ? <Lifetest /> : null}></Route>
                <Route path='/thankyou' element={<ThankYou />}></Route>

            </Routes>

        </BrowserRouter>


    );
}

export default App;
